
//! NOT USED FOR TECO
import React, { useEffect, useState } from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LOCAL_STORAGE } from '../../appConsts';

import logo from '../../assets/images/wbc-logo-white-web.png';

//services
import * as orderActions from '../../redux/actions/orderActions';
import * as profileActions from '../../redux/actions/profileActions';
import * as productActions from '../../redux/actions/productActions';
import { getProfileFromStorage } from '../../services/storageService';




const NavBar = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { order } = useSelector(state => state.orderReducer);
    const { productViewCategory } = useSelector(state => state.productReducer);
    //const { profile } = useSelector(state => state.profileReducer);

    const [localProfile, setLocalProfile] = useState({ id: '' });

    useEffect(() => {
        // check local storage for a token        

        // get profile from local storage
        let profile = getProfileFromStorage();
        if (profile) {
            setLocalProfile(profile);
        }



        //if (lsToken != null) {
        // we have a token, let's get the cart items from the db and then add to redux and localstorage
        // call the get cart from db
        dispatch(orderActions.getCartFromDb());

        // load the profile
        dispatch(profileActions.getProfileFromDb());

        // load products
        //dispatch(productActions.getProducts(productViewCategory));




    }, []);





    const logout = () => {
        dispatch(profileActions.logout())
            .then(() => {
                history.push('/login');
            });
    };

    const onNav = (view) => {


        // set the product category first
        dispatch(productActions.setProductCategory(view))
            .then(() => {
                dispatch(productActions.getProducts(view));
                history.push('/orders');
            });
    };

    return (
        <>
            <Navbar collapseOnSelect expand="lg" variant="dark" className="py-lg-3">
                <Container>
                    <Navbar.Brand href="https://wirelessbusinessconsultants.com/" className="me-lg-5">
                        <img src={logo} alt="Wireless Business Consultants" className="logo-dark" height="32" />
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav">
                        <i className="mdi mdi-menu"></i>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav as="ul" className="me-auto align-items-center">
                            {/* <Nav.Item as="li" className="mx-lg-1">
                                <Nav.Link href="/home" className="active font-16">
                                    Home
                                </Nav.Link>
                            </Nav.Item>
                            */}

                            {/* <Nav.Item className="mx-lg-2">
                                <Nav.Link onClick={() => onNav('Devices')} className="active font-16">Devices</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="mx-lg-2">
                                <Nav.Link onClick={() => onNav('Accessories')} className="active font-16">Accessories</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="mx-lg-2">
                                <Nav.Link href="#" onClick={() => onNav('Tablets & Laptops')} className="active font-16">Tablets & Laptops</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="mx-lg-2">
                                <Nav.Link href="#" onClick={() => onNav('Mobile Broadband & Routers')} className="active font-16">Mobile Broadband & Routers</Nav.Link>
                            </Nav.Item> */}

                        </Nav>

                        <div className="button-list">
                            <Link to="/cart-details" className="btn btn-light">
                                <i className="mdi mdi-basket me-2"></i> My Orders <span className="badge bg-warning text-dark">{order.length}</span>
                            </Link>

                            {localProfile.id === '' ? (<Link to="/login" className="btn btn-outline-light">
                                Login
                            </Link>) : (<Link to="#" onClick={() => logout()} className="btn btn-outline-light">
                                Logout fdsfsdfsdf
                            </Link>)}
                        </div>


                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default NavBar;