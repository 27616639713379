import { ORDER as ActionTypes } from '../actionTypes';
import { cartInit } from '../../services/tecoApi';

const initialState = {
    cart: cartInit,
    cartLines: [],
};


export const tecoReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypes.SET_ORDER:

            return {
                ...state,
                cart: action.payload,
            };
        case ActionTypes.CLEAR_ORDER:

            return {
                ...state,
                cart: initialState.cart,
            };

        default:
            return state;
    };
};