import { API_METHOD, ENDPOINTS } from '../appConsts';
import { handleFetch } from './handleFetch';
const apiRoot = ENDPOINTS.API + '/device';

const deviceInit = {
	"deviceId": 0,
	"deviceName": '',
	"status": '',
	"statusId": 0,
	"buildingId": 0,
	"buildingAddress": '',
	"buildingCorpName": '',
	"customerId": 0,
	"customerName": "",
	"deviceTypeId": 0,
	"deviceTypeShort": "",
	"deviceTypeLong": "",
	"lastCat1InspDate": "",
	"lastPeriodicInspDate": "",
	"lastCat5InspDate": "",
	"cat5DueDate": "",
	"hydroStatusId": 0,
	"hydroStatus": "",
	"codeYear": "",

	"controllerType": "",
	"ppnStatusId": 0,
	"ppnStatus": "",
	"notes": "",
	"brakeComplianceId": 0,
	"brakeCompliance": "",
	"manufacturer": ""
};

export { deviceInit };

export const search = (data) => {
	let url = `${apiRoot}/Search`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
};

export const getAllByCustomerId = (customerId) => {
	let url = `${apiRoot}/GetAllByCustomerId/${customerId}`;

	const options = {
		method: API_METHOD.POST
	};
	return handleFetch(url, options, true);
};

export const getSingle = (id) => {

	let url = `${apiRoot}/GetSingle/${id}`;

	const options = {
		method: API_METHOD.GET
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {
		debugger;
	}
};
export const update = (data) => {
	let url = `${apiRoot}/Update`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
};

export const insert = (data) => {
	let url = `${apiRoot}/Insert`;

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(data)
	};
	return handleFetch(url, options, true);
};

export const verifyNewBuilding = (address) => {
	let url = `${apiRoot}/VerifyNewBuilding`;

	let payload = {
		address: address
	};

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(payload)
	};
	return handleFetch(url, options, true);
};