import { ORDER as ActionTypes } from '../actionTypes';
import { LOCAL_STORAGE } from '../../appConsts';
import * as orderApi from '../../services/orderApi';
import { clearCart } from '../../services/storageService';

export function getCartFromLocalStorage() {
    return async (dispatch) => {
        let payload = [];

        const localStorageOrder = localStorage.getItem(LOCAL_STORAGE.CART);

        if (localStorageOrder !== null) {
            payload = JSON.parse(localStorageOrder);
        }

        dispatch(
            {
                type: ActionTypes.SET_ORDER,
                payload: payload
            }
        );
    };
};


export function getCartFromDb() {

    return async (dispatch) => {
        let payload = [];
        // use the users guid to get the cart from db
        // for now we are getting the order array from local storage.
        // if we have no local storage order then let's build an empty one and save it to redux
        const localStorageOrder = localStorage.getItem(LOCAL_STORAGE.CART);

        if (localStorageOrder !== null) {
            // set the items in localstorage into the array
            payload = JSON.parse(localStorageOrder);
        }

        dispatch(
            {
                type: ActionTypes.SET_ORDER,
                payload: payload
            }
        );
    };
};


export function removeDeviceFromCart(newList) {

    return async (dispatch) => {
        let payload = [];
        // debugger;
        // // remove all items in the list
        // const localStorageOrder = localStorage.removeItem(LOCAL_STORAGE.CART);

        // // add new list into storage
        // localStorage.setItem(LOCAL_STORAGE.CART, JSON.stringify(newList));



        // let response = await buildingApi.getSingle(id);


        // if (response.hasError === false) {
        //     payload = mapObject(response.data);
        // } else {
        //     return null;
        //}

        dispatch(
            {
                type: ActionTypes.SET_ORDER,
                payload: newList
            }
        );
    };
};


export function submitOrder(orderForm) {

    return async (dispatch) => {
        let resp = {};
        // get the cart from local storage
        const localStorageOrder = localStorage.getItem(LOCAL_STORAGE.CART);


        if (localStorageOrder !== null) {
            // set the items in localstorage into the array
            let cart = JSON.parse(localStorageOrder);
            let orderDetails = [];
            cart.forEach(element => {
                let financeTerms = null;
                if (element.purchaseTypeId === 'EPP Financing') {
                    financeTerms = element.financeTermsId;
                }

                let purchaseType = element.purchaseTypeId;
                if (element.productCategory === 'Accessories' || element.productCategory === 'Rate Plan') {
                    // make all accessories full price
                    purchaseType = 'Full Price';
                }

                let temp = {
                    "id": element.id,
                    "quantity": element.quantity,
                    "purchaseTypeId": purchaseType,
                    "financeTermsId": financeTerms,
                    "ratePlanId": element.ratePlanId.toString(),
                    "productCategory": element.productCategory,
                    "discount": element.promoDiscount && element.promoDiscount.toString(),
                    "productName": null
                };

                orderDetails.push(temp);

                if (element.ratePlanId !== 0) {
                    let temp2 = {
                        "id": element.ratePlanId,
                        "quantity": element.quantity,
                        "purchaseTypeId": purchaseType, // rate plans are all full price
                        "financeTermsId": null,
                        "ratePlanId": "0",
                        "productCategory": "Rate Plan",
                        "discount": null,
                        "productName": null
                    };
                    orderDetails.push(temp2);
                }

            });

            orderForm.recordType = "Order";
            orderForm.isNewCustomer = orderForm.customerType.label === "New" ? true : false;

            orderForm.details = orderDetails;
            await orderApi.submitOrder(orderForm)
                .then((result) => {

                    if (result.data.hasError === false) {
                        resp.data = result.data;
                        resp.hasError = resp.data.hasError;
                        //clear cart from local storage
                        clearCart();
                    }
                })
                .catch(() => {
                    resp.hasError = true;
                });
        }

        dispatch(
            {
                type: ActionTypes.SET_ORDER,
                payload: []
            }
        );

        return resp;
    };
};


export function addDeviceToCart(device) {

    return async (dispatch) => {
        let payload = [];

        const localStorageOrder = localStorage.getItem(LOCAL_STORAGE.CART);

        if (localStorageOrder !== null) {

            payload = JSON.parse(localStorageOrder);
        }

        payload.push(device);
        localStorage.setItem(LOCAL_STORAGE.CART, JSON.stringify(payload));

        dispatch(
            {
                type: ActionTypes.SET_ORDER,
                payload: payload
            }
        );
    };
};
