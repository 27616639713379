import { API_METHOD, APPROVED_BY_LIST, ENDPOINTS } from '../appConsts';
import { handleFetch } from './handleFetch';
const apiRoot = ENDPOINTS.API + '/teco';

const cartInit = {
    "cartId": 0,
    "cartName": 0,
    "statusId": 0,
    "status": "",
    "totalNumberOfItems": 0,
    "zohoOrderId": "",
    "zohoOrderStatus": "",
    "orderSubmittedOn": "",
    "dateCreated": ""
};

const cartLineInit = {
    "cartDetailId": 0,
    "cartId": 0,
    "device": "",
    "ratePlan": "",
    "quantity": 0,
    "firstName": "",
    "lastName": "",
    "imei": "",
    "sim": "",
    "ipAddress": "",
    "location": "",
    "shippingAddress": "",
    "costCenter": "",
    "approvedBy": APPROVED_BY_LIST[0].value,
    "createdBy": "",
    "dateCreated": "",
    "modifiedBy": "",
    "dateModified": ""
};



export { cartInit, cartLineInit };


export const submitOrder = (cartId) => {

    let url = `${apiRoot}/SubmitOrder/${cartId}`;

    const options = {
        method: API_METHOD.GET
    };

    return handleFetch(url, options, true);
};


export const removeOrder = (cartId) => {

    let url = `${apiRoot}/RemoveOrder/${cartId}`;

    const options = {
        method: API_METHOD.GET
    };

    return handleFetch(url, options, true);
};


// get single order
export const getSingleOrder = (cartId) => {
    let url = `${apiRoot}/GetSingleOrder/${cartId}`;

    const options = {
        method: API_METHOD.GET
    };

    return handleFetch(url, options, true);
};

// get all my orders
export const getAllOrders = () => {
    let url = `${apiRoot}/GetAllOrders`;

    const options = {
        method: API_METHOD.GET
    };
    return handleFetch(url, options, true);
};

// insert order
export const insertOrder = (data) => {
    let url = `${apiRoot}/InsertOrder`;

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(data)
    };
    return handleFetch(url, options, true);
};

// update order
export const updateOrder = (data) => {
    let url = `${apiRoot}/UpdateOrder`;

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(data)
    };
    return handleFetch(url, options, true);
};

// teco submit order

// change order status
export const changeOrderStatus = () => {
    let url = `${apiRoot}/GetAllOrders`;

    const options = {
        method: API_METHOD.GET
    };
    return handleFetch(url, options, true);
};












//! ORDER LINES

// get all cart lines
export const getCartLines = (cartId) => {
    let url = `${apiRoot}/GetOrderLines/${cartId}`;

    const options = {
        method: API_METHOD.GET
    };

    return handleFetch(url, options, true);
};

export const insertOrderLine = (data) => {
    let url = `${apiRoot}/InsertOrderDetail`;

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(data)
    };
    return handleFetch(url, options, true);
};

export const updateOrderLine = (data) => {
    let url = `${apiRoot}/UpdateOrderDetail`;

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(data)
    };
    return handleFetch(url, options, true);
};

export const deleteOrderLine = (lineId) => {
    let url = `${apiRoot}/RemoveOrderDetail/${lineId}`;

    const options = {
        method: API_METHOD.GET
    };
    return handleFetch(url, options, true);
};