//@flow
import React, { Suspense } from 'react';
import { Container } from 'react-bootstrap';


// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const Topbar = React.lazy(() => import('./Topbar'));
const Navbar = React.lazy(() => import('./Navbar'));

const loading = () => <div className="text-center">loading</div>;

const HorizontalLayout = ({ children }) => {
    // const dispatch = useDispatch();

    // const [isMenuOpened, setIsMenuOpened] = useState(false);

    // /**
    //  * Open the menu when having mobile screen
    //  */
    // const openMenu = () => {
    //     setIsMenuOpened(!isMenuOpened);
    //     if (document.body) {
    //         if (isMenuOpened) {
    //             document.body.classList.remove('sidebar-enable');
    //         } else {
    //             document.body.classList.add('sidebar-enable');
    //         }
    //     }
    // };

    // useEffect(() => {
    //     dispatch(changeLayout(layoutConstants.LAYOUT_HORIZONTAL));
    // }, [dispatch]);

    return (
        <>
            <div className="content">
                <Suspense fallback={loading()}>
                    <Topbar
                        isMenuOpened={false}
                        navCssClasses="topnav-navbar"
                        topbarDark={true}
                    />

                    <Navbar isMenuOpened={false} />

                    <Container fluid>
                        <Suspense fallback={loading()}>{children}</Suspense>
                    </Container>
                </Suspense>
            </div>

        </>
    );
};

export default HorizontalLayout;
