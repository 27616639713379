import { API_METHOD, ENDPOINTS, SITE } from '../appConsts';
import { handleFetch } from './handleFetch';
const apiRootAuth = ENDPOINTS.API + '/auth';
const apiRootUser = ENDPOINTS.API + '/user';

const userInit = {
	"id": "",
	"email": "",
	"firstLast": "",
	"firstName": "",
	"lastFirst": "",
	"lastName": "",
	"phoneFormatted": "",
	"phone10Digits": "",
	"statusId": 1,
	"status": "",
	"password": "",
	"confirmPassword": "",
	"dateCreated": "",
	"modifiedBy": "",
	"dateModified": "",
	"code": ""
};

export { userInit };

export const register = (data) => {

	let url = `${apiRootAuth}/AddUser`;

	let request = {
		email: data.email,
		password: data.password,
		firstName: data.firstName,
		lastName: data.lastName,
		code: data.code,
		site: SITE
	};

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(request)
	};

	return handleFetch(url, options, false);
};


export const sendResetToken = (data) => {

	let url = `${apiRootAuth}/ResetPassword`;

	let request = {
		"email": data,
		"callBackUrl": ENDPOINTS.CALL_BACK_URL
	};


	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(request)
	};
	return handleFetch(url, options, false);
};


export const login = (data) => {

	let url = `${apiRootAuth}/UserLogin`;

	let request = {
		email: data.username,
		password: data.password,
		site: SITE
	};

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(request)
	};
	return handleFetch(url, options, false);
};

export const updatePassword = (data) => {
	// user wants to update their own password
	let url = `${apiRootAuth}/UpdatePasswordFromSite`;

	let request = {
		"email": data.email,
		"password": data.password,
		"token": data.token
	};

	const options = {
		method: API_METHOD.POST,
		body: JSON.stringify(request)
	};
	return handleFetch(url, options, false);
};

export const getUserProfile = (userGuid) => {

	let url = `${apiRootUser}/GetSingle/${userGuid}`;

	const options = {
		method: API_METHOD.GET
	};

	try {
		return handleFetch(url, options, true);
	} catch (e) {
		debugger;
	}
};
