import { ORDER } from "./redux/actionTypes";

export const VERSION = '7.27.23.106';
export const API_VERSION = '1.1.23.104';
export const COPYRIGHT = '2024 © Wireless Business Consultants';
export const ENDPOINTS = {
	// local dev
	//API: 'http://localhost:5001/api',
	//CALL_BACK_URL: 'http://localhost:3001/update-password'

	// dev
	API: 'https://wbc-admin-api.azurewebsites.net/api',
	CALL_BACK_URL: 'https://wirelesswarehousedirect.com//update-password'


	// prod
	//API: 'https://api.dsp-wireless.com/api',
	//CALL_BACK_URL: 'https://prodapi.caa-apps.com/reset-password'
};


// device, accessories, mobile broadbad & routers, tablets

export const RECAPTCHA = '6LeYoLYUAAAAAGJxTOuLd5UKJJ8jHLrofvBXdEpW';
export const SITE = 'TECO';


export const BRAND_COLORS = {
	PRIMARY: '#005FAA',
	SECONDARY: '#FFDC00'

}


//export const SITE = 'dsp-wireless.com';

export const API_METHOD = {
	POST: 'POST',
	GET: 'GET',
	DELETE: 'DELETE'
};
export const LOCAL_STORAGE = {
	PROFILE: 'teco-profile',
	TOKEN: 'teco-token',
	CART: 'teco-cart',
};
export const SESSION_STORAGE = {
	CUSTOMER_ID: 'customer-id',
	AGENT_ID: 'agent-id',
	TOKEN: 'teco-token',
	ORDER_ID: 'order-id',
};
export const STATUS = {
	ACTIVE: { ID: 1, VALUE: 'Active' },
	INACTIVE: { ID: 0, VALUE: 'Inactive' },
};

// purchase type
export const PURCHASE_TYPES = [
	//{ value: "BYOD", label: 'BYOD' },
	//{ value: "EPP Financing", label: 'EPP Financing' },
	{ value: "Full Price", label: 'Full Price' }
];

// 
export const APPROVED_BY_LIST = [
	{ value: 'Mark Cunningham', label: 'Mark Cunningham' },
	{ value: 'Glenn Reed', label: 'Glenn Reed' }
];


// installed app options
export const INSTALLED_APPS = ["Ementor", "Google Maps", "Paycom", "Waze", "Flex", "Chime", "ADP", "Amazon A to Z"];