import { combineReducers } from 'redux';
import { profileReducer } from './reducers/profileReducer';
import { customerReducer } from './reducers/customerReducer';
import { agentReducer } from './reducers/agentReducer';
import { buildingReducer } from './reducers/buildingReducer';
import { inspectionDashboardReducer } from './reducers/inspectionDashboardReducer';
import { scheduleReducer } from './reducers/scheduleReducer';
import { deviceReducer } from './reducers/deviceReducer';
import { orderReducer } from './reducers/orderReducer';
import { productReducer } from './reducers/productReducer';
import { tecoReducer } from './reducers/tecoReducer';

export const appReducer = combineReducers(
	{
		profileReducer,
		customerReducer,
		agentReducer,
		buildingReducer,
		scheduleReducer,
		inspectionDashboardReducer,
		deviceReducer,
		orderReducer,
		productReducer, tecoReducer
	});