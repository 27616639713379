//! USED FOR TECO
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';


// All layouts/containers
import DefaultLayout from '../layouts/Default';
//import HorizontalLayout from '../layouts/Horizontal/HorizontalLayout';
import HorizontalLayout from '../newLayouts/HorizontalLayout';

import { authProtectedFlattenRoutes, publicProtectedFlattenRoutes } from './index';

const Routes = (props) => {

    const { profile } = useSelector(state => state.profileReducer);

    const layout = {
        layoutType: "topnav",
        layoutWidth: "fluid",
        leftSideBarTheme: "default",
        leftSideBarType: "fixed",
        showRightSidebar: false
    };


    return (
        <BrowserRouter>
            <Switch>
                <Route path={publicProtectedFlattenRoutes.map((r) => r['path'])}>
                    <DefaultLayout {...props} layout={layout}>
                        <Switch>
                            {publicProtectedFlattenRoutes.map((route, index) => {
                                return (
                                    !route.children && (
                                        <route.route
                                            key={index}
                                            path={route.path}
                                            roles={route.roles}
                                            exact={route.exact}
                                            component={route.component}
                                        />
                                    )
                                );
                            })}
                        </Switch>
                    </DefaultLayout>
                </Route>

                <Route path={authProtectedFlattenRoutes.map((r) => r['path'])}>
                    <HorizontalLayout {...props} user={profile}>
                        <Switch>
                            {authProtectedFlattenRoutes.map((route, index) => {
                                return (
                                    !route.children && (
                                        <route.route
                                            key={index}
                                            path={route.path}
                                            roles={route.roles}
                                            exact={route.exact}
                                            component={route.component}
                                        />
                                    )
                                );
                            })}
                        </Switch>
                    </HorizontalLayout>
                </Route>
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;
