import { PROFILE as ActionTypes } from '../actionTypes';

const initialState = {
	profile: {
		id: '',
		firstName: '',
		lastName: '',
		firstLast: '',
		lastFirst: '',
		email: '',
		phone: '',
		isAdmin: false,
		isActive: false, // show up on lists but can't access system	
		hasAccess: false,		// can access system
		avatar: ''
	},
	roles: [],
};


export const profileReducer = (state = initialState, action) => {

	switch (action.type) {
		case ActionTypes.SET_PROFILE:
			return {
				...state,
				profile: action.payload
			};
		case ActionTypes.CLEAR_PROFILE:

			return {
				...initialState,
			};

		default:
			return state;
	};
};